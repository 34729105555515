import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import * as routes from "../../router/constants";

export const FloatingButton = ({
  text = "Contact Us",
  href = routes.APPLY,
  className = "",
}) => {
  return (
    <div className={`floating-button ${className}`}>
      <Link to={{ pathname: href }}>
        <button className="btn btn-tertiary" aria-label={text}>
          {text}
        </button>
      </Link>
    </div>
  );
};

FloatingButton.propTypes = {
  text: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
};

export default FloatingButton;
