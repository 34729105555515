import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { isArray } from "lodash";

import { ANIMATION_DELAY } from "constants";

const HexagonContent = ({
  header,
  list,
  text,
  image,
  imageAlignment = "left",
  anchorId,
  link,
  color,
  boldIndex,
  animationDelay = ANIMATION_DELAY.default,
}) => {
  const renderHeader = () => {
    if (!isArray(header)) return header;

    return header.map((part, index) => (
      <React.Fragment key={index}>
        {index === boldIndex ? <strong>{part}</strong> : part}{" "}
      </React.Fragment>
    ));
  };

  return (
    <section className="hexagon-content" id={anchorId}>
      <div className="container">
        <div className={`row row-container ${imageAlignment}`} key={header}>
          {/* left image */}
          {imageAlignment === "left" && (
            <div className="col-lg-5 col-xl-6 text-center image-container">
              <ScrollAnimation
                animateIn="fadeInLeft"
                delay={animationDelay}
                initiallyVisible={false}
                animateOnce={true}
              >
                <img src={image} alt={header} className="img-fluid" />
              </ScrollAnimation>
            </div>
          )}

          {/* content */}
          <div className="col-lg-7 col-xl-6">
            <div className="content-container">
              <ScrollAnimation
                animateIn={
                  imageAlignment === "left" ? "fadeInRight" : "fadeInLeft"
                }
                delay={animationDelay}
                initiallyVisible={false}
                animateOnce={true}
              >
                <h1 className="text-secondary text-start">{renderHeader()}</h1>
                <hr className={`bg-${color}`} />
                <ul>
                  {list.map((listItem, index) => (
                    <li key={index}>{listItem}</li>
                  ))}
                </ul>

                <p>{text}</p>

                <div className="btn-row">
                  <Link to={{ pathname: link }}>
                    <button className={`btn btn-${color}`}>Apply now</button>
                  </Link>
                </div>
              </ScrollAnimation>
            </div>
          </div>

          {/* right image */}
          {imageAlignment === "right" && (
            <div className="col-lg-5 col-xl-6 text-center text-lg-end image-container">
              <ScrollAnimation
                animateIn="fadeInRight"
                delay={animationDelay}
                initiallyVisible={false}
                animateOnce={true}
              >
                <img src={image} alt={header} className="img-fluid" />
              </ScrollAnimation>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

HexagonContent.propTypes = {
  header: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  imageAlignment: PropTypes.oneOf(["left", "right"]),
  anchorId: PropTypes.string,
  link: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  boldIndex: PropTypes.number,
  animationDelay: PropTypes.number,
};

export default HexagonContent;
