import React from "react";
import PropTypes from "prop-types";
import ScrollAnimation from "react-animate-on-scroll";
import { ANIMATION_DELAY } from "constants";

const contentVariants = {
  default: (
    <p className="global">
      Fill out the form below if you want to start your journey now and become a
      Connect courier.
    </p>
  ),
  index: <p className="global">Start your journey with DPD now.</p>,
};

export const TopContent = ({
  variant = "default",
  title = "Why become a Connect driver?",
  description = "You can earn up to £18 per hour, get paid weekly and use your own vehicle! You'll pick up all your parcels from a local collection point and you'll be delivering in your local area.",
}) => {
  return (
    <section className="top-content">
      <div className="container">
        <ScrollAnimation
          animateIn="fadeInLeft"
          delay={ANIMATION_DELAY.default}
          initiallyVisible={false}
          animateOnce={true}
        >
          <div className="content-container">
            <h1>{title}</h1>

            <p className="global">{description}</p>
            {contentVariants[variant]}
          </div>
        </ScrollAnimation>
      </div>
    </section>
  );
};

TopContent.propTypes = {
  variant: PropTypes.oneOf(["default", "index"]),
  title: PropTypes.string,
  description: PropTypes.string,
};

export default TopContent;
