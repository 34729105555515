import React, { useEffect, useState, useContext } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.scss";
import {
  ActionTracker,
  TrackerProvider,
} from "@dpdgroupuk/react-event-tracker";
import Index from "./pages/Index";
import ReadyToApply from "./pages/ReadyToApply";
import NotFound from "./pages/NotFound";
import Register from "./pages/Register";
import Connect from "./pages/Connect";
import { isMobile } from "./utils/userAgent";

import httpService from "./utils/interceptors";
import Loader from "./components/Loader";
import LoaderProvider from "./context/Loader";
import { LoaderContext } from "./context/Loader";

function App() {
  const [windowWidth, setWindowWidth] = useState(0);
  const [urlCampaign, setUrlCampaign] = useState("");

  useEffect(() => {
    setWindowWidth(window.innerWidth);

    let campaign = window.location.search ? window.location.search : null;
    if (campaign) {
      localStorage.setItem("urlCampaign", campaign);
      setUrlCampaign(campaign);
    } else if (localStorage.getItem("urlCampaign") !== null) {
      setUrlCampaign(localStorage.getItem("urlCampaign"));
    }
  }, []);

  const tracker = new ActionTracker({
    projectRef: "DriverPortal",
    // if debug is true then ConsoleLogger is used
    debug: false,
    // set the url to the app engine where you installed @dpdgroupuk/express-action-tracker
    url: process.env.REACT_APP_ANALYTICS_URL,
  });

  useEffect(() => {
    tracker.setContext({
      url: window.location.href,
    });

    tracker.logEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SetupAPI = () => {
    const { showLoader, hideLoader } = useContext(LoaderContext);

    useEffect(() => {
      httpService.setupRequestInterceptors(showLoader);
      httpService.setupResponseInterceptors(hideLoader);
    }, []);

    return null;
  };

  return (
    <TrackerProvider tracker={tracker}>
      <LoaderProvider>
        <SetupAPI />

        <Router>
          <Switch>
            <Route path="/apply">
              <ReadyToApply
                windowWidth={windowWidth}
                urlCampaign={urlCampaign}
              />
            </Route>
            <Route path="/register">
              <Register
                pageId="register"
                windowWidth={windowWidth}
                urlCampaign={urlCampaign}
              />
            </Route>
            <Route path="/connect">
              <Connect
                pageId="connect"
                windowWidth={windowWidth}
                urlCampaign={urlCampaign}
              />
            </Route>
            <Route exact path="/">
              <Index
                pageId="home"
                windowWidth={windowWidth}
                urlCampaign={urlCampaign}
              />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </Router>

        <ToastContainer
          position={isMobile ? "top-center" : "bottom-left"}
          autoClose={2500}
          hideProgressBar={true}
          newestOnTop={true}
          closeButton={false}
          closeOnClick
          icon={false}
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
          theme="error"
          limit={3}
        />
        <Loader />
      </LoaderProvider>
    </TrackerProvider>
  );
}

export default App;
