import React from "react";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

import logo from "assets/images/logo.svg";
import barsLight from "assets/images/bars-light.svg";

const navigationLinks = [
  { path: "", label: "Choose Connect", eventKey: "home" },
  { path: "connect", label: "Connect Driver", eventKey: "connect" },
];

export const Header = ({ active, urlCampaign = "" }) => {
  const portalHost = process.env.REACT_APP_PORTAL_HOST;

  return (
    <header>
      <div className="container">
        <Navbar variant="dark" expand="lg" className="row">
          <div className="col-lg-4 logo-container">
            {/* logo */}
            <Link
              className="logo"
              to={{ pathname: `/${urlCampaign}` }}
              aria-label="Driver Recruitment Home"
            >
              <img
                src={logo}
                alt="Driver Recruitment"
                width="89"
                height="auto"
              />
            </Link>

            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              aria-label="Toggle navigation menu"
            >
              <img src={barsLight} alt="Menu" width="20" height="auto" />
            </Navbar.Toggle>
          </div>
          <div className="col-lg-8">
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav activeKey={active}>
                {navigationLinks.map(({ path, label, eventKey }) => (
                  <Nav.Link
                    key={eventKey}
                    href={`/${path}${urlCampaign}`}
                    eventKey={eventKey}
                  >
                    <span>{label}</span>
                  </Nav.Link>
                ))}
                <Nav.Link
                  href={`${portalHost}login${urlCampaign}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>
                    Sign In
                    <FontAwesomeIcon
                      icon={faUser}
                      className="text-tertiary ms-1"
                      aria-hidden="true"
                    />
                  </span>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </div>
    </header>
  );
};

Header.propTypes = {
  active: PropTypes.string,
  urlCampaign: PropTypes.string,
};

export default Header;
