import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";

import * as routes from "../../router/constants";
import PrivateRoute from "../../components/PrivateRoute";
import Agreement from "./Agreement";
import EmailSignUp from "./EmailSignUp";
import CheckYourEmail from "./CheckYourEmail";
import NotFound from "../NotFound";
import "./index.scss";
import Connect from "../Connect";

function ReadyToApply({ windowWidth, urlCampaign }) {
  const [email, setEmail] = useState(sessionStorage.getItem("email"));
  const [agreeChecked, setAgreeChecked] = useState(
    sessionStorage.getItem("agreeChecked")
  );

  const update = () => {
    setEmail(sessionStorage.getItem("email"));
    setAgreeChecked(sessionStorage.getItem("agreeChecked"));
  };

  return (
    <div className="ready-to-apply">
      <Switch>
        <Route path={routes.APPLY} exact>
          <EmailSignUp
            pageId="EmailSignUp"
            windowWidth={windowWidth}
            urlCampaign={urlCampaign}
            update={update}
          />
        </Route>
        <Route path={routes.CONNECT} exact>
          <Connect
            pageId="Connect"
            windowWidth={windowWidth}
            urlCampaign={urlCampaign}
            // update={update}
          />
        </Route>
        <PrivateRoute
          path={routes.AGREEMENT}
          redirectURL={routes.APPLY}
          isValid={!!email}
          component={() => (
            <Agreement
              pageId="Agreement"
              windowWidth={windowWidth}
              urlCampaign={urlCampaign}
              update={update}
            />
          )}
        />
        <PrivateRoute
          path={routes.CHECK_YOUR_EMAIL}
          redirectURL={routes.APPLY}
          isValid={!!agreeChecked}
          component={() => (
            <CheckYourEmail
              pageId="CheckYourEmail"
              windowWidth={windowWidth}
              urlCampaign={urlCampaign}
            />
          )}
        />
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </div>
  );
}

export default ReadyToApply;
