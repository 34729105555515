import * as Yup from "yup";

export const contactUsValidationSchema = () => {
  return Yup.object().shape({
    name: Yup.string()
      .min(8, "Name must be between 8 and 100 Characters in length.")
      .max(100, "Name must be between 8 and 100 Characters in length.")
      .required("Please enter the name."),
    address: Yup.string().required("Please enter the address."),
    postcode: Yup.string()
      .matches(
        /^(\w{1,2}\d{1,2}\w?\s\d\w{1,2})$/,
        "Please enter a valid UK Postcode, for example: B69 4DA"
      )
      .required("Please enter the postcode."),
    contact_number: Yup.string()
        .matches(/^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/, "Please enter a valid UK phone number.")
        .required("Please enter the contact number."),
    vehicle_registration: Yup.string().required("Please enter the vehicle registration."),
    email_address: Yup.string()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Please enter a valid email address."
      )
      .required("Please enter the email address."),
    is_british_irish: Yup.string()
      .oneOf(['yes', 'no'], 'Please select whether you are a British or Irish citizen.')
      .required('Please select whether you are a British or Irish citizen.'),
    rtw_share_code: Yup.string()
      .when('is_british_irish', {
        is: 'no',
        then: () => Yup.string()
          .matches(
            /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[a-zA-Z0-9])[a-zA-Z0-9 ]{11}$/gm,
            "Please enter a valid share code, for example: A1A B2B CC3"
          )
          .required("Please enter the share code."),
        otherwise: () => Yup.string(),
      }),
    valid_uk_licence: Yup.bool().oneOf(
      [true],
      "Sorry, You need to have a valid UK driving license."
    ),
    // uk_work_permit: Yup.bool().oneOf(
    //   [true],
    //   "Sorry, you need to have the right to work in the UK."
    // ),
  });
};
