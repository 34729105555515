import React, { useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import axios from "axios";
import { find, isEmpty } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

import { handleRecaptcha } from "../../utils/captcha";
import { contactUsValidationSchema } from "../../validation/contactUsValidationSchema";

const ConnectForm = ({depots}) => {
  const [success, setSuccess] = useState(false);
  const [sumbmitted, setSumbmitted] = useState(false);
  const [formMessage, setFormMessage] = useState("");
  const [depot, setDepot] = useState(""); // set depot entered in the URL
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const encodedValue = urlParams.get("location");
    const decodedValue = decodeURIComponent(encodedValue); // decode url
    const lowercaseUrl = decodedValue.toLowerCase();

    const validDepot = find(depots, item => {
      return item.collection_point_name.toLowerCase() === lowercaseUrl;
    })?.collection_point_name;

    if (!isEmpty(validDepot)) {
      setDepot(validDepot);
    } else {
      setDepot("");
    }
  }, [depots]);

  return (
    <div className="contact-form">
      <Formik
        initialValues={{
          name: "",
          postcode: "",
          address: "",
          email_address: "",
          contact_number: "",
          vehicle_registration: "",
          vehicle_make: "",
          vehicle_model: "",
          rtw_share_code: "",
          valid_uk_licence: false,
          available_tues_sat: false,
          is_british_irish: "",
          notes: "",
        }}
        validationSchema={contactUsValidationSchema}
        onSubmit={async (values, { resetForm }) => {
          const isRecaptchaPass = await handleRecaptcha(
            "CONTACT_US",
            process.env.REACT_APP_CAPTCHA_PASS
          );

          if (isRecaptchaPass) {
            values.url_location = depot;
            values.connect_driver = true;
            console.log("values", values);
            await axios
              .post(`${process.env.REACT_APP_API_HOST}/contact/`, values)
              .then(response => {
                setSumbmitted(true);
                setFormMessage(
                  "Thank you for your interest, our local depot will be in touch with you soon."
                );
                setSuccess(true);
                resetForm();
              })
              .catch(function (error) {
                setSumbmitted(true);
                setFormMessage(
                  "Form submission failed. Please try to resubmit the form."
                );
                setSuccess(false);
              });
          }
        }}
      >
        {({ values, handleChange, errors, touched }) => {
          return (
              <Form className="needs-validation">
                  {depot && (
                      <div className="location">
                          <FontAwesomeIcon icon={faLocationDot}/>
                          {depot}
                      </div>
                  )}
                  <div className="mb-15">
                      <label htmlFor="name" className="form-label">
                          Name <sup>*</sup>
                      </label>
                      <Field
                          name="name"
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Enter full name"
                      />
                      {errors.name && touched.name ? (
                          <div className="error-text mt-7">{errors.name}</div>
                      ) : (
                          <div className="error-text mt-7"></div>
                      )}
                  </div>

                  <div className="mb-15">
                      <label htmlFor="address" className="form-label">
                          Address <sup>*</sup>
                      </label>
                      <Field
                          name="address"
                          type="text"
                          className="form-control"
                          id="address"
                          placeholder="Enter address"
                      />
                      {errors.address && touched.address ? (
                          <div className="error-text mt-7">{errors.address}</div>
                      ) : (
                          <div className="error-text mt-7"></div>
                      )}
                  </div>

                  <div className="mb-15">
                      <label htmlFor="postcode" className="form-label">
                          Postcode <sup>*</sup>
                      </label>
                      <Field
                          name="postcode"
                          type="text"
                          className="form-control text-uppercase"
                          id="postcode"
                          placeholder="Enter postcode"
                      />
                      {errors.postcode && touched.postcode ? (
                          <div className="error-text mt-7">{errors.postcode}</div>
                      ) : (
                          <div className="error-text mt-7"></div>
                      )}
                  </div>

                  <div className="mb-15">
                      <label htmlFor="email_address" className="form-label">
                          Email address <sup>*</sup>
                      </label>
                      <Field
                          name="email_address"
                          type="email"
                          className="form-control"
                          id="email_address"
                          placeholder="Enter email address"
                      />
                      {errors.email_address && touched.email_address ? (
                          <div className="error-text mt-7">{errors.email_address}</div>
                      ) : (
                          <div className="error-text mt-7"></div>
                      )}
                  </div>

                  <div className="mb-15">
                      <label htmlFor="contact_number" className="form-label">
                          Phone number <sup>*</sup>
                      </label>
                      <Field
                          name="contact_number"
                          type="text"
                          className="form-control"
                          id="contact_number"
                          placeholder="Enter phone number"
                      />
                      {errors.contact_number && touched.contact_number ? (
                          <div className="error-text mt-7">{errors.contact_number}</div>
                      ) : (
                          <div className="error-text mt-7"></div>
                      )}
                  </div>

                  <div className="mb-10">
                      <label className="form-label d-block">
                          Are you a British or Irish citizen? <sup>*</sup>
                      </label>
                      <div className="form-check form-check-inline">
                          <Field
                              type="radio"
                              name="is_british_irish"
                              value="yes"
                              className="form-check-input"
                              id="citizenYes"
                          />
                          <label className="form-check-label" htmlFor="citizenYes">
                              Yes
                          </label>
                      </div>
                      <div className="form-check form-check-inline">
                          <Field
                              type="radio"
                              name="is_british_irish"
                              value="no"
                              className="form-check-input"
                              id="citizenNo"
                          />
                          <label className="form-check-label" htmlFor="citizenNo">
                              No
                          </label>
                      </div>
                      {errors.is_british_irish && touched.is_british_irish ? (
                          <div className="error-text mt-7">{errors.is_british_irish}</div>
                      ) : (
                          null
                      )}
                  </div>

                  <div className="mb-15">
                      <label htmlFor="rtw_share_code" className="form-label">
                          Right to Work Share Code {values.is_british_irish === 'no' && <sup>*</sup>}
                      </label>
                      <Field
                          name="rtw_share_code"
                          type="text"
                          className="form-control"
                          id="rtw_share_code"
                          placeholder="Enter Right to Work Share Code"
                          disabled={values.is_british_irish !== 'no'}
                      />

                      <p className="mt-2">
                          You can get the right to work share code{" "}
                          <a
                              href="https://www.gov.uk/prove-right-to-work/get-a-share-code-online"
                              target="_blank"
                              rel="noreferrer"
                          >
                              here
                          </a>
                          .
                      </p>
                      {errors.rtw_share_code && touched.rtw_share_code ? (
                          <div className="error-text mt-7">{errors.rtw_share_code}</div>
                      ) : (
                          <div className="error-text mt-7"></div>
                      )}
                  </div>

                  <div className="mb-15">
                      <label htmlFor="vehicle_registration" className="form-label">
                          Vehicle Registration <sup>*</sup>
                      </label>
                      <Field
                          name="vehicle_registration"
                          type="text"
                          className="form-control"
                          id="vehicle_registration"
                          placeholder="Enter Vehicle Registration"
                      />
                      {errors.vehicle_registration && touched.vehicle_registration ? (
                          <div className="error-text mt-7">{errors.vehicle}</div>
                      ) : (
                          <div className="error-text mt-7"></div>
                      )}
                  </div>

                  <div className="mb-15">
                      <label htmlFor="vehicle_make" className="form-label">
                          Vehicle Make
                      </label>
                      <Field
                          name="vehicle_make"
                          type="text"
                          className="form-control"
                          id="vehicle_make"
                          placeholder="Enter Vehicle Make"
                      />
                      {errors.vehicle_make && touched.vehicle_make ? (
                          <div className="error-text mt-7">{errors.vehicle_make}</div>
                      ) : (
                          <div className="error-text mt-7"></div>
                      )}
                  </div>

                  <div className="mb-15">
                      <label htmlFor="vehicle_model" className="form-label">
                          Vehicle Model
                      </label>
                      <Field
                          name="vehicle_model"
                          type="text"
                          className="form-control"
                          id="vehicle_make"
                          placeholder="Enter Vehicle Model"
                      />
                      {errors.vehicle_model && touched.vehicle_model ? (
                          <div className="error-text mt-7">{errors.vehicle_model}</div>
                      ) : (
                          <div className="error-text mt-7"></div>
                      )}
                  </div>


                  <div className="mb-10">
                      <label className="form-label d-block mb-15">
                          Do you have a valid UK driving licence? <sup>*</sup>
                      </label>
                      <div className="form-check form-check-inline">
                          <Field
                              name="valid_uk_licence"
                              className="form-check-input"
                              type="checkbox"
                              id="valid_uk_licence"
                          />
                          <label
                              className="form-check-label"
                              htmlFor="valid_uk_licence"
                          >
                              Yes, I have a valid UK driving licence
                          </label>
                      </div>
                      {errors.valid_uk_licence && touched.valid_uk_licence ? (
                          <div className="error-text alignment">
                              {errors.valid_uk_licence}
                          </div>
                      ) : (
                          <div className="error-text alignment"></div>
                      )}
                  </div>
                  <div>
                      <label className="form-label d-block mb-15">
                          Can you work Tuesday to Saturday?
                      </label>
                      <div className="form-check form-check-inline m-0">
                          <Field
                              name="available_tues_sat"
                              className="form-check-input"
                              type="checkbox"
                              id="available_tues_sat"
                          />
                          <label className="form-check-label" htmlFor="available_tues_sat">
                              Yes, I can work Tuesday to Saturday
                          </label>
                      </div>
                      {errors.available_tues_sat && touched.available_tues_sat ? (
                          <div className="error-text alignment mt-2 mb-lg-0">
                              {errors.available_tues_sat}
                          </div>
                      ) : (
                          <div className="error-text alignment mt-2 mb-lg-0"></div>
                      )}
                  </div>

                  <div className="mb-15">
                      <label htmlFor="notes" className="form-label">
                          Notes (Optional)
                      </label>
                      <Field
                          name="notes"
                          as="textarea"
                          className="form-control"
                          id="notes"
                          placeholder="Enter notes"
                      />
                  </div>

                  {sumbmitted ? (
                      <div
                          className={
                              success ? "message-block success" : "message-block error"
                          }
                      >
                          {formMessage}
                      </div>
                  ) : (
                      <div className="message-block d-none d-lg-block"></div>
                  )}

                  <div className="btn-row">
                      <a
                          href="https://www.dpd.co.uk/owner-driver-privacy-notice.jsp"
                          target="_blank"
                          rel="noreferrer"
                      >
                          Privacy Policy
                      </a>
                      <button type="submit" className="btn btn-secondary float-end">
                          Submit
                      </button>
                  </div>
              </Form>
          );
        }}
      </Formik>
    </div>
  );
};
export default ConnectForm;
