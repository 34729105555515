import React, { useMemo, useEffect, useState, useCallback } from "react";
import { Trackable } from "@dpdgroupuk/react-event-tracker";
import Header from "components/Header";
import Banner from "components/Banner";
import TopContent from "../../components/TopContent";
import useScript from "hooks/useScript";
import ConnectApply from "./components/ConnectApply";
import "./index.scss";
import { getDepots } from "../Register/api/depot";

export const Connect = ({ pageId, windowWidth, urlCampaign }) => {
  const [depots, setDepots] = useState([]);

  // Scroll to top on mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const localContext = useMemo(
    () => ({
      page: "connect",
      pageId,
      // ... more params in local context
    }),
    [pageId]
  );

  const fetchDepots = useCallback(async () => {
    try {
      const response = await getDepots();
      setDepots(response);
    } catch (error) {
      console.error("Error fetching depots:", error);
    }
  }, []);

  useEffect(() => {
    fetchDepots();
  }, [fetchDepots]);

  useScript(
    `https://www.google.com/recaptcha/enterprise.js?render=${process.env.REACT_APP_CAPTCHA_PASS}`
  );

  const bannerProps = useMemo(
    () => ({
      subtitle: ["Deliver locally and earn up to ", "£18 per hour"],
      title: ["Become a ", "DPD Connect", " driver"],
      boldIndex: 1,
      subtitleBoldIndex: 1,
      top: "title",
      banner: "banner2",
      windowWidth,
      color: "blue",
      titleBreak: true,
    }),
    [windowWidth]
  );

  return (
    <Trackable
      loadId="connectPage"
      interfaceId="connectInterface"
      context={localContext}
    >
      <div className="connect">
        <div className="hero-section">
          <Header active="connect" urlCampaign={urlCampaign} />
          <Banner {...bannerProps} />
        </div>

        <TopContent />

        <ConnectApply depots={depots} />
      </div>
    </Trackable>
  );
};

export default Connect;
