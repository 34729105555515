import React from "react";
import PropTypes from "prop-types";
import { isArray } from "lodash";
import ScrollAnimation from "react-animate-on-scroll";
import { MOBILE_BREAKPOINT } from "constants";
import scrollArrow from "assets/images/scroll-arrow.svg";
import scrollArrowBlue from "assets/images/scroll-arrow-blue.svg";

const SCROLL_ARROW_SIZE = 48;
const DESKTOP_HEADER_HEIGHT = 88;

const ScrollButton = ({ color, onClick }) => (
  <img
    src={color === "blue" ? scrollArrowBlue : scrollArrow}
    alt="scroll down"
    width={SCROLL_ARROW_SIZE}
    className="btn-scroll d-none d-md-block"
    onClick={onClick}
  />
);

ScrollButton.propTypes = {
  color: PropTypes.oneOf(["blue", "default"]),
  onClick: PropTypes.func.isRequired,
};

export const Banner = ({
  title,
  boldIndex,
  top = "title",
  subtitle,
  banner,
  windowWidth,
  color = "default",
  subtitleBoldIndex,
  titleBreak = false,
}) => {
  const scrollPage = () => {
    const bannerElement = document.getElementById("banner");
    const bannerHeight = bannerElement?.offsetHeight ?? 0;
    const headerHeight =
      windowWidth < MOBILE_BREAKPOINT ? 0 : DESKTOP_HEADER_HEIGHT;
    window.scrollTo(0, bannerHeight + headerHeight);
  };

  const renderText = (text, boldIndex, titleBreak) => {
    if (!isArray(text)) return text;

    return text.map((part, index) => (
      <React.Fragment key={index}>
        {index === boldIndex ? (
          <strong>
            {titleBreak ? <br /> : ""}
            {part}
          </strong>
        ) : (
          part
        )}{" "}
      </React.Fragment>
    ));
  };

  return (
    <div className={`banner left ${banner}`} id="banner">
      <div className="container outer-container">
        <div className="content">
          <ScrollAnimation
            animateIn="fadeInLeft"
            initiallyVisible={true}
            animatePreScroll={true}
            animateOnce={true}
          >
            {top === "title" && (
              <h2>{renderText(title, boldIndex, titleBreak)}</h2>
            )}

            <h5>{renderText(subtitle, subtitleBoldIndex)}</h5>

            {top !== "title" && (
              <h2>{renderText(title, boldIndex, titleBreak)}</h2>
            )}
          </ScrollAnimation>
        </div>

        <ScrollButton color={color} onClick={scrollPage} />
      </div>
    </div>
  );
};

Banner.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  boldIndex: PropTypes.number,
  top: PropTypes.oneOf(["title", "subtitle"]),
  subtitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  banner: PropTypes.string.isRequired,
  windowWidth: PropTypes.number.isRequired,
  color: PropTypes.oneOf(["blue", "default"]),
  subtitleBoldIndex: PropTypes.number,
};

export default Banner;
