import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { ANIMATION_DELAY } from "constants";
import * as routes from "../../router/constants";

export const VideoSection = ({ heading, action = true, videoUrl }) => {
  return (
    <section className="video-section text-center">
      <div className="container">
        <ScrollAnimation
          animateIn="fadeIn"
          delay={ANIMATION_DELAY.default}
          initiallyVisible={false}
          animateOnce={true}
        >
          <h3 className="text-center text-secondary">{heading}</h3>
          <iframe
            width="560"
            height="315"
            src={videoUrl}
            title={heading}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; 
            picture-in-picture"
            allowFullScreen
          ></iframe>

          {action && (
            <div className="btn-row">
              <Link to={{ pathname: routes.APPLY }}>
                <button className="btn btn-primary">How To Apply</button>
              </Link>
            </div>
          )}
        </ScrollAnimation>
      </div>
    </section>
  );
};

VideoSection.propTypes = {
  heading: PropTypes.string.isRequired,
  action: PropTypes.bool,
  videoUrl: PropTypes.string.isRequired,
  videoTitle: PropTypes.string,
};

export default VideoSection;
