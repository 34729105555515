import React from "react";
import ConnectForm from "../../../../components/ConnectForm";

export const ChoiceApply = ({depots}) => {
  return (
    <section className="content-apply image-content" id="contactForm">
      <div className="plcholder"></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 content-container">
            <div className="gutter right">
              <ConnectForm depots={depots} />
            </div>
          </div>
          <div className="col-lg-6"></div>
        </div>
      </div>
    </section>
  );
};

export default ChoiceApply;
