import React, { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { Trackable } from "@dpdgroupuk/react-event-tracker";
import Header from "components/Header";
import Banner from "components/Banner";
import Cards from "components/Cards";
import HexagonContent from "components/HexagonContent";
import VideoSection from "components/VideoSection";
import TopContent from "../../components/TopContent";
import Vans from "./components/Vans";
import FloatingButton from "components/FloatingButton";
import driver1 from "assets/images/driver1.png";
import driver2 from "assets/images/driver2.png";
import driver3 from "assets/images/driver3.png";
import * as routes from "../../router/constants";

const CONNECT_DRIVER_CONTENT = {
  header: ["Connect", " delivery driver"],
  boldIndex: 0,
  list: [
    "Earn up to £18 per hour and get paid weekly",
    "Use your own vehicle",
    "Deliver close to you - you'll be making deliveries in your local area",
    "Easy to use app to manage your earnings",
  ],
};

const OWNER_DRIVER_CONTENT = {
  header: "Owner Driver",
  list: [
    "From £50K to £60K per route",
    "You are responsible for your own tax and insurance contributions",
  ],
};

const OWNER_DRIVER_WORKER_CONTENT = {
  header: "Owner Driver Worker",
  text: "Enjoy the freedom of being self employed, with the security of knowing your holidays will be taken care of.",
  list: ["From £27K", "Sick Pay", "Paid Holiday", "Pension"],
};

const VIDEO_SECTIONS = {
  connect: {
    heading:
      "Watch our short film to see how easy it is to get started and start earning as a DPD Connect courier",
    action: false,
    videoUrl: "https://www.youtube.com/embed/KovDLD-RB6U",
  },
  owner: {
    heading: "Watch our film to see a day in the life of an Owner Driver",
    action: true,
    videoUrl: "https://www.youtube.com/embed/l_9eotejSec",
  },
};

export const Index = ({ pageId, windowWidth, urlCampaign }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const localContext = useMemo(
    () => ({
      page: "home",
      pageId,
    }),
    [pageId]
  );

  const bannerProps = useMemo(
    () => ({
      subtitle: ["Deliver locally and earn up to ", "£18 per hour"],
      title: ["Become a ", "DPD Connect", " driver"],
      boldIndex: 1,
      subtitleBoldIndex: 1,
      top: "title",
      banner: "banner1",
      windowWidth,
      color: "blue",
      titleBreak: true,
    }),
    [windowWidth]
  );

  return (
    <Trackable
      loadId="homePage"
      interfaceId="homeInterface"
      context={localContext}
    >
      <div className="index">
        <div className="hero-section">
          <Header active="home" urlCampaign={urlCampaign} />
          <Banner {...bannerProps} />
        </div>

        <TopContent variant="index" />

        <VideoSection urlCampaign={urlCampaign} {...VIDEO_SECTIONS.connect} />

        <HexagonContent
          imageAlignment="left"
          image={driver2}
          header={CONNECT_DRIVER_CONTENT.header}
          boldIndex={CONNECT_DRIVER_CONTENT.boldIndex}
          list={CONNECT_DRIVER_CONTENT.list}
          urlCampaign={urlCampaign}
          link={routes.CONNECT}
          color="tertiary"
        />

        <Cards urlCampaign={urlCampaign} />

        <Vans windowWidth={windowWidth} />

        <HexagonContent
          imageAlignment="right"
          image={driver1}
          header={OWNER_DRIVER_CONTENT.header}
          list={OWNER_DRIVER_CONTENT.list}
          anchorId="driver"
          urlCampaign={urlCampaign}
          link={routes.APPLY}
          color="primary"
        />

        <HexagonContent
          imageAlignment="left"
          image={driver3}
          header={OWNER_DRIVER_WORKER_CONTENT.header}
          list={OWNER_DRIVER_WORKER_CONTENT.list}
          anchorId="worker"
          urlCampaign={urlCampaign}
          text={OWNER_DRIVER_WORKER_CONTENT.text}
          link={routes.APPLY}
          color="primary"
        />

        <VideoSection urlCampaign={urlCampaign} {...VIDEO_SECTIONS.owner} />

        <FloatingButton urlCampaign={urlCampaign} href={routes.APPLY} />
      </div>
    </Trackable>
  );
};

Index.propTypes = {
  pageId: PropTypes.string.isRequired,
  windowWidth: PropTypes.number.isRequired,
  urlCampaign: PropTypes.string,
};

Index.defaultProps = {
  urlCampaign: "",
};

export default Index;
